import React from "react";
import { Link } from "gatsby-plugin-intl";
import { HighlightedText, urlCreateAccount } from "../../system42";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderCompare } from "../../components/HeaderCompare";
import { HtmlContent } from "../../components/HtmlContent";
import { Testimonials } from "../../components/Testimonials";
import {
  testimonialThomas,
  testimonialJoshua,
  testimonialPaul,
} from "../../helpers";
import * as helperStyles from "../helpers.module.css";
import * as styles from "./compare.module.css";

import imageDashboardSessions from "../../images/compareDashboardSessions.jpg";
import { CompareLastParagraph } from "../../components/helpers/CompareLastParagraph";

const ContentPage = () => (
  <Layout
    title={"UserZoom vs. Userbrain"}
    metaDescription={
      "Thinking about switching from UserZoom to Userbrain? We wanted to make the decision a little easier for you - compare the two side by side and decide."
    }
  >
    <article>
      <HeaderCompare
        title={
          <>
            <span className={helperStyles.h1Serif}>UserZoom vs.</span> Userbrain
          </>
        }
        content={
          "Thinking about making the switch from UserZoom to Userbrain? We get it; it’s always good to explore your options - which is why we’ve made comparing them easier for you. Discover 8 ways Userbrain and UserZoom differ and choose which one is the right choice for you:"
        }
        imageSrc={imageDashboardSessions}
        imageAlt={
          "Userbrain dashboard showing a list of remote user testing videos"
        }
      />
      <div className={helperStyles.container}>
        <HtmlContent className={styles.htmlContent}>
          <h2>1. Register and go vs call back</h2>
          <p>
            With Userbrain you can{" "}
            <a href={urlCreateAccount}>
              get your first results in less than an hour
            </a>
            . Whereas UserZoom not only requires you to add a long list of your
            personal details but you can’t just sign up and start using their
            services. The team will first review your details and email, and
            then call you back with a demo.
          </p>
          <p>
            A rather tedious and unnecessary procedure we reckon. With Userbrain
            (we leveraged maximum UX) it’s so simple to use we don’t even do
            demos.
          </p>

          <h2>2. Annual commitment vs. flexibility</h2>
          <p>
            UserZoom requires you to sign up to an annual contract - which is
            not only a big financial investment but a timely one too. Userbrain
            is founded and led by UX Designers and we know just how important it
            is to stay flexible, and test (or not test) when it’s needed. In
            innovative companies, things change fast - which is why we know the
            value of flexible subscriptions.
          </p>
          <p>
            Userbrain won’t ask you for a long term commitment upfront, you can
            date us for a while and then choose whether you want to take things
            to the next level.
          </p>

          <h2>3. Transparent pricing</h2>
          <p>
            When you click on the pricing page at UserZoom you’ll find tons of
            useful information - but not the one thing you came looking for -
            actual prices. At Userbrain we follow a strict, transparent pricing
            strategy, you’ll quickly find that our prices are as clear as day.
            Whether you’re a corporation or a one-woman/man show -{" "}
            <Link to={"/pricing"}>everyone pays the same</Link>.
          </p>

          <h2>4. Standard user testing features</h2>
          <p>
            UserZoom offers an array of complex services, which, quite frankly,
            can leave you feeling overwhelmed. Whereas at Userbrain we take
            pride in keeping things simple and effective by offering the same
            service and features to everyone. You have access to all features
            needed to carry out standard unmoderated remote user tests as well
            as access to our worldwide pool of over testers.
          </p>

          <h2>5. Discover a more efficient way of user testing</h2>
          <p>
            Speaking of keeping things simple and effective, Userbrain’s clean
            interface is optimized for efficient user testing - which saves you
            time, money and frustration.
          </p>

          <h2>6. Automated user testing vs. manual</h2>
          <p>
            To really figure out what’s working or not you should carry out UX
            tests more than once, ideally at regular intervals. With Userbrain
            you can subscribe & save to test regularly and turn it into a habit
            - rather than a once-off thing you forget to check up on.
          </p>

          <h2>7. Userbrain's honesty and efficiency</h2>
          <p>
            At Userbrain we are more about under-promising and over-delivering
            rather than the other way around.
          </p>
          <p>
            When you carry out user tests, you might want to select specific
            criteria - such as age, location and interests - for the audience.
            Both UserZoom and Userbrain offer this option, so far so good.
          </p>
          <p>
            The difference lies in the fact that we will, however, only allow
            you to select a specific target if they are available. Whereas in
            UserZoom it might happen that only days later you receive an email
            to inform you that they couldn’t find anyone.
          </p>
          <p>Which is just a waste of everyone’s time and energy, isn’t it?</p>

          <CompareLastParagraph />
        </HtmlContent>
      </div>
      <div className={helperStyles.container}>
        <Testimonials
          testimonials={[testimonialJoshua, testimonialThomas, testimonialPaul]}
        />
        <GetStartedBox
          className={helperStyles.spacingLg}
          title={
            <>
              Ready for your first{" "}
              <HighlightedText cursor>user test?</HighlightedText>
            </>
          }
          textBottom={
            "Get set up in minutes. Your first test is ready in a few hours."
          }
        />
      </div>
    </article>
  </Layout>
);

export default ContentPage;
